import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { PrimaryButton } from "../../components/buttons";
import { DangerButton } from "../../components/buttons";
import { Loading } from "../../components/loading";
import { Page } from "../../components/page";
import {
  useBulkDeleteOrders,
  useCreateOrder,
  useStaleOrders,
} from "../../hooks/orders";
import { useRequiredSearchParam } from "../../hooks/required-search-params";
import { useSelectedStore } from "../../hooks/selected-store";
import { useVendor } from "../../hooks/vendors";
export function OrderCleanup() {
  const vendorId = useRequiredSearchParam("vendorId");
  const { vendor, loading: vendorLoading } = useVendor(vendorId);
  const { selectedStore } = useSelectedStore();
  const bulkDeleteOrders = useBulkDeleteOrders();
  const { staleOrders, loading: ordersLoading } = useStaleOrders(vendorId);
  const [loading, setLoading] = useState(false);

  const createOrder = useCreateOrder();
  const navigate = useNavigate();

  const navigateToOrder = useCallback(
    (orderId: string) => {
      if (!selectedStore) {
        throw new Error("No store");
      }
      navigate(`/stores/${selectedStore.id}/orders/${orderId}/edit`);
    },
    [navigate, selectedStore],
  );

  const deleteExistingOrdersAndCreateNew = useCallback(() => {
    if (!selectedStore) {
      throw new Error("No store");
    }
    setLoading(true);
    bulkDeleteOrders(staleOrders)
      .then(() => {
        createOrder(selectedStore.id, { vendor_id: vendorId }).then((order) => {
          navigateToOrder(order.id);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    selectedStore,
    vendorId,
    staleOrders,
    createOrder,
    navigateToOrder,
    bulkDeleteOrders,
  ]);

  const openMostRecentOrder = useCallback(() => {
    const mostRecentOrder = staleOrders[0];
    navigateToOrder(mostRecentOrder.id);
  }, [staleOrders, navigateToOrder]);

  if (
    !selectedStore ||
    ordersLoading ||
    vendorLoading ||
    staleOrders.length === 0 ||
    loading
  ) {
    return <Loading />;
  }

  const existingOrderLabelWithCount =
    staleOrders.length === 1
      ? "existing order"
      : `${staleOrders.length} existing orders`;
  const createDescription = `Discard the ${existingOrderLabelWithCount} and create a new one. Any updates to the existing order(s) will be lost.`;
  const openDescription = `Continue with the most recent order (updated ${new Date(staleOrders[0].updated_at).toLocaleDateString()}).`;

  return (
    <Page title="Existing Order Found">
      <div className="flex flex-col gap-4 max-w-2xl mx-auto">
        <div className="flex items-center gap-2 text-orange-500 pt-10">
          <ExclamationTriangleIcon className="h-6 w-6" aria-hidden="true" />
          <span>
            {staleOrders.length === 1
              ? `There is an existing draft order for ${vendor?.name || "this vendor"}. `
              : `There are ${staleOrders.length} existing draft orders for ${vendor?.name || "this vendor"}. `}
            Would you like to:
          </span>
        </div>
        <ActionPanel title="Open Existing" description={openDescription}>
          <PrimaryButton onClick={openMostRecentOrder}>
            Open Existing
          </PrimaryButton>
        </ActionPanel>
        <ActionPanel
          title="Cancel &amp; Create New"
          description={createDescription}
        >
          <DangerButton onClick={deleteExistingOrdersAndCreateNew}>
            Cancel &amp; Create New
          </DangerButton>
        </ActionPanel>
      </div>
    </Page>
  );
}

function ActionPanel({
  title,
  description,
  children,
}: { title: string; description: string; children: React.ReactNode }) {
  return (
    <div className="bg-gray-50 rounded-md">
      <div className="p-4 sm:p-5">
        <div className="flex justify-between items-start gap-4">
          <div>
            <h3 className="text-base font-semibold text-gray-900">{title}</h3>
            <div className="mt-2 text-sm text-gray-500">
              <p>{description}</p>
            </div>
          </div>
          <div className="flex-shrink-0">{children}</div>
        </div>
      </div>
    </div>
  );
}
