import { useLocation } from "react-router";

export function useRequiredSearchParam(name: string) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const value = searchParams.get(name);
  if (!value) {
    throw new Error(`No ${name} in search params`);
  }
  return value;
}
