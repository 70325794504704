import { Select } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import { FourOhFour } from "../../components/404";
import {
  LinkButton,
  PrimaryButton,
  SmallSecondaryButton,
} from "../../components/buttons";
import {
  checkboxClass,
  checkboxInvalidClass,
  inputClass,
  inputInvalidClass,
} from "../../components/form-styles";
import { Field, FieldDescription, Row, Section } from "../../components/forms";
import { Loading } from "../../components/loading";
import { Page } from "../../components/page";
import { Spinner } from "../../components/spinner";
import { AlertContext } from "../../contexts/alert-context";
import { StoreVendorContext } from "../../contexts/store-vendor-context";
import {
  type OrderSettings,
  OrderSettingsSchema,
  qohMinPercents,
  salesPeriodDays,
} from "../../data/order-settings";
import type { Store } from "../../data/store";
import { type Vendor, VendorSchema } from "../../data/vendor";
import { SendMethodLabels } from "../../helpers/send-method-labels";
import { useSelectedStore } from "../../hooks/selected-store";
import { useVendor } from "../../hooks/vendors";

// Some dark magic lifted from https://medium.com/@ofirstiberdev/overriding-type-properties-in-typescript-like-a-pro-54203a817253
type Override<
  Type,
  NewType extends { [key in keyof Type]?: NewType[key] },
> = Omit<Type, keyof NewType> & NewType;

type FormValues = Override<
  Vendor,
  {
    order_settings: Override<
      OrderSettings,
      {
        store_emails: { email: string; send_order: boolean }[];
      }
    >;
  }
>;

const validationSchema = VendorSchema.shape({
  order_settings: OrderSettingsSchema.shape({
    store_emails: Yup.array()
      .of(
        Yup.object()
          .shape({
            email: Yup.string().email().required(),
            send_order: Yup.boolean().required(),
          })
          .required(),
      )
      .required(),
  }),
});

// small wrapper component to preload the vendor and store data
export function EditVendor() {
  const { selectedStore } = useSelectedStore();
  const { vendorId } = useParams();
  const { vendor, loading } = useVendor(vendorId);

  if (loading) {
    return <Loading />;
  }

  if (!vendor || !selectedStore) {
    return <FourOhFour />;
  }

  return <EditVendorPopulated vendor={vendor} store={selectedStore} />;
}

function EditVendorPopulated({
  vendor,
  store,
}: { vendor: Vendor; store: Store }) {
  const { addErrorAlert, addSuccessAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const { updateVendor } = useContext(StoreVendorContext);

  // functions to build form returned by useForm() hook
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    formState,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...vendor,
      order_settings: {
        ...vendor.order_settings,
        store_emails: store.emails.map((email) => ({
          email,
          send_order: vendor.order_settings.store_emails.some(
            (storeEmail) => storeEmail === email,
          ),
        })),
      },
    },
  });

  const onSubmit = useCallback(
    (data: FormValues) => {
      const updatedVendor = {
        ...vendor,
        ...data,
        order_settings: {
          ...vendor.order_settings,
          ...data.order_settings,
          store_emails: data.order_settings.store_emails
            .filter((storeEmail) => storeEmail.send_order)
            .map((storeEmail) => storeEmail.email),
        },
      };

      updateVendor(updatedVendor)
        .then(() => {
          addSuccessAlert("Vendor updated", { keepAfterRouteChange: true });
          navigate(`/stores/${store.id}/vendors`);
        })
        .catch(addErrorAlert);
    },
    [updateVendor, store, vendor, navigate, addSuccessAlert, addErrorAlert],
  );

  const {
    fields: representativeFields,
    append: appendRepresentative,
    remove: removeRepresentative,
  } = useFieldArray({
    control,
    name: "order_settings.representatives",
  });

  const addRepresentative = useCallback(() => {
    appendRepresentative({
      first_name: "",
      last_name: "",
      division: "",
      email: "",
      work_phone: "",
      mobile_phone: "",
      send_order: true,
    });
  }, [appendRepresentative]);

  const { fields: storeEmailFields } = useFieldArray({
    control,
    name: "order_settings.store_emails",
  });

  const normalizedNames = useMemo(() => {
    switch (store.shipping_address?.state) {
      case "AR":
        return [
          ["DSD", "DSD Link"],
          ["BEES", "Bees"],
          ["SGWS_AR", "SGWS"],
        ];
      case "OK":
        return [
          ["ABSB_OK", "A&B / SB (Muskogee)"],
          ["ABOK_OK", "A&B (Oklahoma City)"],
          ["ABTU_OK", "A&B (Tulsa)"],
          ["ARMA_OK", "Armada"],
          ["ATSN_OK", "Artisan"],
          ["CPTL_OK", "Capital"],
          ["HAND_OK", "Handcrafted"],
          ["LDF_OK", "LDF"],
          ["PVSN_OK", "Provisions"],
          ["REVN_OK", "Revolution"],
          ["RNDC_OK", "RNDC"],
          ["SGWS_OK", "SGWS"],
        ];
      case "NY":
        return [
          ["CLRO_NY", "Clare Rose"],
          ["EMPR_NY", "Empire"],
          ["OPICI_NY", "Opici"],
          ["SGWS_NY", "SGWS"],
        ];

      default:
        return [];
    }
  }, [store.shipping_address]);

  return (
    <Page title={vendor.name}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => reset()}
        className="flex flex-col gap-2 w-[32rem]"
      >
        <Field
          label="Disabled"
          name="disabled"
          errorMessage={errors.disabled?.message}
        >
          <input
            {...register("disabled")}
            type="checkbox"
            className={errors.disabled ? checkboxInvalidClass : checkboxClass}
          />
        </Field>
        <Field
          label="Vendor Integration"
          name="normalized_name"
          errorMessage={errors.normalized_name?.message}
        >
          <Select
            {...register("normalized_name")}
            className={errors.normalized_name ? inputInvalidClass : inputClass}
            disabled={normalizedNames.length === 0}
          >
            <option key="none" value="">
              No Integration
            </option>
            {normalizedNames.length > 0 &&
              normalizedNames.map(([normalizedName, label]) => (
                <option key={normalizedName} value={normalizedName}>
                  {label}
                </option>
              ))}
          </Select>
        </Field>
        <Section>Order Settings</Section>
        <FieldDescription>
          When the quantity on hand for a product is less than the{" "}
          <span className="font-bold">QOH Threshold Percent</span> of the sales
          over the <span className="font-bold">Sales Period</span>, a reorder is
          triggered for the product. The{" "}
          <span className="font-bold">Reorder Weeks</span> setting determines
          how many weeks of inventory to order based on sales history.
        </FieldDescription>
        <Field
          label="QOH Threshold Percent"
          name="qoh_threshold_percent"
          errorMessage={
            errors.order_settings?.defaults?.qoh_threshold_percent?.message
          }
        >
          <Select
            {...register("order_settings.defaults.qoh_threshold_percent", {
              valueAsNumber: true,
            })}
            className={
              errors.order_settings?.defaults?.qoh_threshold_percent
                ? inputInvalidClass
                : inputClass
            }
          >
            {qohMinPercents.map((percent) => (
              <option key={percent} value={percent}>
                {percent}%
              </option>
            ))}
          </Select>
        </Field>
        <Field
          label="Sales Period"
          name="sales_period"
          errorMessage={
            errors.order_settings?.defaults?.sales_period_days?.message
          }
        >
          <Select
            {...register("order_settings.defaults.sales_period_days", {
              valueAsNumber: true,
            })}
            className={
              errors.order_settings?.defaults?.sales_period_days
                ? inputInvalidClass
                : inputClass
            }
          >
            {salesPeriodDays.map((days) => (
              <option key={days} value={days}>
                {days} days
              </option>
            ))}
          </Select>
        </Field>
        <Field
          label="Reorder Weeks"
          name="reorder_weeks"
          errorMessage={errors.order_settings?.defaults?.reorder_weeks?.message}
        >
          <input
            {...register("order_settings.defaults.reorder_weeks", {
              valueAsNumber: true,
            })}
            type="number"
            min="1"
            max="12"
            className={
              errors.order_settings?.defaults?.reorder_weeks
                ? inputInvalidClass
                : inputClass
            }
          />
        </Field>
        <Field
          label="Default Send Method"
          name="send_method"
          errorMessage={errors.order_settings?.defaults?.send_method?.message}
        >
          <Select
            {...register("order_settings.defaults.send_method")}
            className={
              errors.order_settings?.defaults?.send_method
                ? inputInvalidClass
                : inputClass
            }
          >
            <option key="none" value="">
              No Default
            </option>
            {vendor.order_settings.send_methods.map((method) => (
              <option key={method} value={method}>
                {SendMethodLabels[method] || method}
              </option>
            ))}
          </Select>
        </Field>

        <Section>Representatives</Section>
        <div className="flex flex-col p-4 bg-gray-100 rounded-sm border border-gray-200 gap-2">
          {representativeFields.map((field, index) => (
            <div key={field.id} className="bg-white p-2 rounded-sm">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => removeRepresentative(index)}
                  className="p2 border border-red-500 rounded-sm text-red-500 hover:bg-red-100 hover:text-red-700"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
              <Field
                label="Email"
                name={`order_settings.representatives.${index}.email`}
                errorMessage={
                  errors.order_settings?.representatives?.[index]?.email
                    ?.message
                }
              >
                <input
                  {...register(`order_settings.representatives.${index}.email`)}
                  placeholder="jane.doe@example.com"
                  type="email"
                  className={
                    errors.order_settings?.representatives?.[index]?.email
                      ?.message
                      ? inputInvalidClass
                      : inputClass
                  }
                />
              </Field>
              <Row>
                <Field
                  label="First Name"
                  name={`order_settings.representatives.${index}.first_name`}
                  errorMessage={
                    errors.order_settings?.representatives?.[index]?.first_name
                      ?.message
                  }
                >
                  <input
                    {...register(
                      `order_settings.representatives.${index}.first_name`,
                    )}
                    placeholder="Jane"
                    className={
                      errors.order_settings?.representatives?.[index]
                        ?.first_name?.message
                        ? inputInvalidClass
                        : inputClass
                    }
                  />
                </Field>
                <Field
                  label="Last Name"
                  name={`order_settings.representatives.${index}.last_name`}
                  errorMessage={
                    errors.order_settings?.representatives?.[index]?.last_name
                      ?.message
                  }
                >
                  <input
                    {...register(
                      `order_settings.representatives.${index}.last_name`,
                    )}
                    placeholder="Doe"
                    className={
                      errors.order_settings?.representatives?.[index]?.last_name
                        ?.message
                        ? inputInvalidClass
                        : inputClass
                    }
                  />
                </Field>
              </Row>
              <Row>
                <Field
                  label="Work Phone"
                  name={`order_settings.representatives.${index}.work_phone`}
                  errorMessage={
                    errors.order_settings?.representatives?.[index]?.work_phone
                      ?.message
                  }
                >
                  <input
                    {...register(
                      `order_settings.representatives.${index}.work_phone`,
                    )}
                    placeholder="555-555-5555"
                    className={
                      errors.order_settings?.representatives?.[index]
                        ?.work_phone?.message
                        ? inputInvalidClass
                        : inputClass
                    }
                  />
                </Field>
                <Field
                  label="Mobile Phone"
                  name={`order_settings.representatives.${index}.mobile_phone`}
                  errorMessage={
                    errors.order_settings?.representatives?.[index]
                      ?.mobile_phone?.message
                  }
                >
                  <input
                    {...register(
                      `order_settings.representatives.${index}.mobile_phone`,
                    )}
                    placeholder="555-555-5555"
                    className={
                      errors.order_settings?.representatives?.[index]
                        ?.mobile_phone?.message
                        ? inputInvalidClass
                        : inputClass
                    }
                  />
                </Field>
              </Row>
              <Row>
                <Field
                  label="Division"
                  name={`order_settings.representatives.${index}.division`}
                  errorMessage={
                    errors.order_settings?.representatives?.[index]?.division
                      ?.message
                  }
                >
                  <input
                    {...register(
                      `order_settings.representatives.${index}.division`,
                    )}
                    placeholder="Sales"
                    className={
                      errors.order_settings?.representatives?.[index]?.division
                        ?.message
                        ? inputInvalidClass
                        : inputClass
                    }
                  />
                </Field>
                <Field
                  label="Send Order"
                  name={`order_settings.representatives.${index}.send_order`}
                  errorMessage={
                    errors.order_settings?.representatives?.[index]?.send_order
                      ?.message
                  }
                >
                  <input
                    {...register(
                      `order_settings.representatives.${index}.send_order`,
                    )}
                    type="checkbox"
                    className={
                      errors.order_settings?.representatives?.[index]
                        ?.send_order?.message
                        ? checkboxInvalidClass
                        : checkboxClass
                    }
                  />
                </Field>
              </Row>
            </div>
          ))}
          <div className="flex justify-center pt-2">
            <SmallSecondaryButton type="button" onClick={addRepresentative}>
              Add Representative
            </SmallSecondaryButton>
          </div>
        </div>
        <Section>Also Send Orders To</Section>
        <div className="grid grid-flow-row gap-2 justify-start">
          {storeEmailFields.map((field, index) => (
            <div
              key={field.id}
              className="grid grid-flow-col gap-2 justify-start items-center"
            >
              <input
                {...register(`order_settings.store_emails.${index}.send_order`)}
                type="checkbox"
                className={
                  errors.order_settings?.representatives?.[index]?.send_order
                    ?.message
                    ? checkboxInvalidClass
                    : checkboxClass
                }
              />
              <span>{field.email}</span>
            </div>
          ))}
        </div>

        <div className="flex gap-2 justify-center pt-4">
          <PrimaryButton type="submit" disabled={formState.isSubmitting}>
            {formState.isSubmitting && <Spinner />}
            Update
          </PrimaryButton>
          <LinkButton to={`/stores/${store.id}/vendors`}>Cancel</LinkButton>
        </div>
      </form>
    </Page>
  );
}
