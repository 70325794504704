import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import { StoreService } from "../services/stores";

export function useStoreService() {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(
    () => new StoreService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );
}
