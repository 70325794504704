import { Navigate, Route, Routes } from "react-router";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense } from "react";
import { FourOhFour } from "../../components/404";
import ErrorBoundary from "../../components/error";
import { Loading } from "../../components/loading";
import { Dashboard } from "../dashboard";
import { Orders } from "../orders";
import { OrderCleanup } from "../orders/cleanup";
import { EditOrder } from "../orders/edit";
import { OrderSummary } from "../orders/summary";
import { Products } from "../products";
import { Reports } from "../reports";
import { Vendors } from "../vendors";
import { EditVendor } from "../vendors/edit";
import { Add } from "./add";
import { Edit } from "./edit";

function Stores() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/add" element={<Add />} />
          <Route path="/:id/edit" element={<Edit />} />
          <Route
            path="/:id/vendors"
            element={
              <ErrorBoundary>
                <Vendors />
              </ErrorBoundary>
            }
          />
          <Route path="/:id/vendors/:vendorId/edit" element={<EditVendor />} />
          <Route path="/:id/products" element={<Products />} />
          <Route path="/:id/products/:productId/edit" element={<Products />} />
          <Route path="/:id/reports">
            <Route
              index
              element={<Navigate to="sales-velocity-report" replace />}
            />
            <Route path="*" element={<Reports />} />
          </Route>
          <Route path="/:id/orders" element={<Orders />} />
          <Route path="/:id/orders/cleanup" element={<OrderCleanup />} />
          <Route path="/:id/orders/:orderId/edit" element={<EditOrder />} />
          <Route
            path="/:id/orders/:orderId/summary"
            element={<OrderSummary />}
          />
          <Route path="/:id" element={<Dashboard />} />
          <Route path="*" element={<FourOhFour />} />
        </Routes>
      </Suspense>
    </QueryClientProvider>
  );
}

export default Stores;
