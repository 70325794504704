import { useCallback, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router";
import { create } from "zustand";
import { AlertContext } from "../contexts/alert-context";
import type { CreateOrder, Order } from "../data/order";
import { OrderStatus } from "../data/order";
import type { OrderItem } from "../data/order-item";
import type { OrderOptions } from "../data/order-options";
import type { Store } from "../data/store";
import type { Vendor } from "../data/vendor";
import { useSelectedStore } from "./selected-store";
import { useStoreService } from "./store-service";

type OrdersState = {
  fetchedStoreId: string;
  orders: Order[];
  loading: boolean;
  setOrders: (orders: Order[], storeId: string) => void;
  addOrder: (order: Order) => void;
  removeOrder: (order: Order) => void;
  setLoading: (loading: boolean) => void;
  replaceOrder: (order: Order) => Order;
};

const useOrdersState = create<OrdersState>((set) => ({
  fetchedStoreId: "",
  orders: [],
  loading: true,
  setOrders: (orders: Order[], storeId: string) =>
    set((state) => ({ ...state, orders, fetchedStoreId: storeId })),
  addOrder: (order: Order) =>
    set((state) => ({ ...state, orders: [...state.orders, order] })),
  replaceOrder: (order: Order) => {
    set((state) => ({
      ...state,
      orders: state.orders.map((o) => (o.id === order.id ? order : o)),
    }));
    return order;
  },
  removeOrder: (order: Order) =>
    set((state) => ({
      ...state,
      orders: state.orders.filter((o) => o.id !== order.id),
    })),
  setLoading: (loading: boolean) => set((state) => ({ ...state, loading })),
}));

export function useCreateOrder() {
  const { addOrder, setLoading } = useOrdersState();
  const storeService = useStoreService();
  const { addErrorAlert } = useContext(AlertContext);

  return useCallback(
    (storeId: string, order: CreateOrder) => {
      setLoading(true);
      return storeService
        .createOrder(storeId, order)
        .then((newOrder) => {
          addOrder(newOrder);
          return newOrder;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, addOrder, setLoading, addErrorAlert],
  );
}

export function useDeleteOrder() {
  const { setLoading, removeOrder } = useOrdersState();
  const storeService = useStoreService();
  const { addErrorAlert } = useContext(AlertContext);

  return useCallback(
    (order: Order) => {
      setLoading(true);
      return storeService
        .deleteOrder(order)
        .then(() => {
          removeOrder(order);
          return true;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, removeOrder, setLoading, addErrorAlert],
  );
}

export function useGetOrder() {
  const { addErrorAlert } = useContext(AlertContext);
  const storeService = useStoreService();

  return useCallback(
    (storeId: string, orderId: string) => {
      return storeService
        .getOrderByStore(storeId, orderId)
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        });
    },
    [storeService, addErrorAlert],
  );
}

export function useSaveOrderOptions() {
  const { orders, replaceOrder, setLoading } = useOrdersState();
  const storeService = useStoreService();
  const { addErrorAlert } = useContext(AlertContext);

  return useCallback(
    (storeId: string, orderId: string, options: OrderOptions) => {
      const order = orders.find((o) => o.id === orderId);
      if (!order) {
        throw new Error("Order not found");
      }
      setLoading(true);
      return storeService
        .saveOrderOptions(storeId, orderId, options)
        .then((updatedOptions) => {
          const updatedOrder = {
            ...order,
            options: updatedOptions,
          };
          replaceOrder(updatedOrder);
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, replaceOrder, setLoading, addErrorAlert],
  );
}

export function useSaveOrderItem() {
  const { orders, replaceOrder, setLoading } = useOrdersState();
  const storeService = useStoreService();
  const { addErrorAlert } = useContext(AlertContext);

  return useCallback(
    (storeId: string, orderId: string, orderItem: OrderItem) => {
      setLoading(true);
      return storeService
        .updateOrderItem(storeId, orderId, orderItem)
        .then((updatedOrderItem) => {
          const order = orders.find((o) => o.id === orderId);
          if (!order) {
            throw new Error("Order not found");
          }
          const updatedOrder = {
            ...order,
            items: order.items.map((i) =>
              i.product.id === updatedOrderItem.product.id
                ? updatedOrderItem
                : i,
            ),
          };

          replaceOrder(updatedOrder);

          return updatedOrderItem;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, replaceOrder, setLoading, addErrorAlert],
  );
}

export function useSubmitOrder() {
  const { replaceOrder, setLoading } = useOrdersState();
  const storeService = useStoreService();
  const { addErrorAlert } = useContext(AlertContext);

  return useCallback(
    (storeId: string, order: Order, method: string) => {
      setLoading(true);
      return storeService
        .submitOrder(storeId, order, method)
        .then(replaceOrder)
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, replaceOrder, setLoading, addErrorAlert],
  );
}

export function useOrders() {
  const { orders, fetchedStoreId, loading, setOrders, setLoading } =
    useOrdersState();

  const { addErrorAlert } = useContext(AlertContext);

  const storeService = useStoreService();

  const { selectedStore } = useSelectedStore();

  useEffect(() => {
    if (!selectedStore) {
      return;
    }
    if (selectedStore.id === fetchedStoreId) {
      return;
    }

    setLoading(true);
    // flush previous orders so we don't show stale data during fetch
    setOrders([], "");
    storeService
      .getOrdersByStore(selectedStore.id)
      .then((orders) => setOrders(orders, selectedStore.id))
      .catch((error: Error) => {
        addErrorAlert(`Invalid orders: ${error.message}`);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    selectedStore,
    fetchedStoreId,
    storeService,
    addErrorAlert,
    setOrders,
    setLoading,
  ]);

  const setOrdersCallback = useCallback(
    (orders: Order[]) => {
      if (!selectedStore) {
        throw new Error("No selected store");
      }
      setOrders(orders, selectedStore.id);
    },
    [setOrders, selectedStore],
  );

  return {
    orders,
    setOrders: setOrdersCallback,
    loading,
    setLoading,
  };
}

export function useOrder(storeId: string | undefined, id: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [order, setOrder] = useState<Order | undefined>();
  const getOrder = useGetOrder();

  const loadOrder = useCallback(
    (storeId: string, id: string) => {
      setLoading(true);
      setOrder(undefined);
      return getOrder(storeId, id)
        .then((order) => {
          setOrder(order);
          setError(undefined);
          return order;
        })
        .catch((error: Error) => {
          setError(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getOrder],
  );

  useEffect(() => {
    if (!storeId || !id) return;

    loadOrder(storeId, id);
  }, [loadOrder, storeId, id]);

  return { loading, order, error };
}

function draftOrdersByVendor(orders: Order[], vendorId: string) {
  return orders.filter(
    (order) =>
      order.vendor_id === vendorId && order.status === OrderStatus.Draft,
  );
}

export function useStaleOrders(vendorId: string) {
  const { orders, loading } = useOrders();
  const staleOrders = draftOrdersByVendor(orders, vendorId).sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  );

  return { staleOrders, loading };
}

export function useMaybeCreateOrder() {
  const { orders } = useOrders();
  const createOrder = useCreateOrder();
  const navigate = useNavigate();

  return useCallback(
    async (store: Store, vendor: Vendor) => {
      const existingDraftOrders = draftOrdersByVendor(orders, vendor.vendor_id);

      // If there are pending orders for this vendor we want the user to clean these up instead of creating another one
      if (existingDraftOrders.length > 0) {
        navigate(
          `/stores/${store.id}/orders/cleanup?vendorId=${vendor.vendor_id}`,
        );

        return;
      }

      const order = await createOrder(store.id, {
        vendor_id: vendor.vendor_id,
        items: [],
      });
      navigate(`/stores/${store.id}/orders/${order.id}/edit`);
    },
    [orders, createOrder, navigate],
  );
}

export function useBulkDeleteOrders() {
  const { orders, setOrders, setLoading } = useOrders();
  const { addErrorAlert } = useContext(AlertContext);
  const storeService = useStoreService();

  return useCallback(
    (deleteOrders: Order[]) => {
      setLoading(true);

      return Promise.all(
        deleteOrders.map((order) => {
          return storeService.deleteOrder(order).catch((error: Error) => {
            addErrorAlert(error.message);
            throw error;
          });
        }),
      )
        .then(() => {
          const newOrders = orders.filter(
            (o) => !deleteOrders.some((e) => e.id === o.id),
          );
          setOrders(newOrders);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [orders, setOrders, setLoading, addErrorAlert, storeService],
  );
}
